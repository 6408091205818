










import { defineComponent, onMounted } from "@vue/composition-api";

export default defineComponent({
  name: "Resources",
  setup() {
    onMounted(() => {
      const article = "https://www.notion.so/Personlig-L-rer-i-Learnlink-72f7ddb86fb049eaafabf55b8a3e010f";
      window.open(article, "_blank")?.focus();
    });
  },
});
